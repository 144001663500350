import styled from "styled-components"
import { animated } from "react-spring"
import { LocalizedLink } from "../Primitives/LocalizedLink"

export const FormContainer = styled.div`
  position: relative;
  margin-top: 40px;
`

export const FormWrapper = styled(animated.div)`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 20px;

  @media (min-width: 800px) {
    flex-direction: row;
    margin: 0 10%;
    margin-bottom: 100px;
  }
`
export const ContactFormHeading = styled.div`
  font-family: "HelveticaNeue Condensed";
  font-size: 1.3rem;
  font-weight: bold;
  font-style: normal;
  line-height: 1.68;
  letter-spacing: 12.5px;
  text-align: left;
  margin: 0 20px;

  & > h3 {
    margin: 0;
  }

  @media (min-width: 800px) {
    margin: 20px 10%;
    font-size: 2rem;
  }
`
export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 800px) {
    width: 50%;
  }

  & * + * {
    margin-top: 20px;
  }
`
export const FormColumnLabel = styled.div`
  margin-top: 20px;
  font-family: "HelveticaNeue Condensed";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  line-height: 2;
  letter-spacing: 7px;
  text-align: left;
  color: #343434;
`

export const PrivacyPolicy = styled.div`
  display: flex;

  & > input[type="checkbox"] {
    appearance: none;
    position: relative;
    cursor: pointer;

    && {
      padding: 0 8px;
    }

    width: 18px;
    height: 18px;
    border: 1px solid #343434;

    &:checked {
      background: #16cc29;
      border: none;
    }
    &:checked:after {
      position: absolute;
      width: 4px;
      height: 10px;
      transform: rotate(45deg);
      box-sizing: content-box;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      bottom: 4px;
      left: 6px;
      z-index: 100;
    }
  }

  & > span {
    max-width: 250px;
    margin: 0 0 0 20px;
    font-family: "HelveticaNeue Condensed";
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 7px;
    text-align: left;
    color: #343434;
  }
`
export const Link = styled(LocalizedLink)`
  text-decoration: underline;
`

export const LoaderContainer = styled(animated.div)`
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  & > p {
    font-family: "HelveticaNeue Condensed";
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 5px;
    color: #343434;
  }
`

export const ButtonContainer = styled.div`
  margin-top: 20px;
`
