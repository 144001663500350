import styled from "styled-components"
import { animated } from "react-spring"

export const MapWrapper = styled.div`
  margin-top: 200px;
  height: 300px;
  width: 100%;

  @media (min-width: 800px) {
    margin-top: 0;
    height: 700px;
  }
`

export const LocationPin = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > #animation > div {
    height: 100px;
    width: 100px;
  }
`

export const Pin = styled.div`
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: grey;
`
