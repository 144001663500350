import React from "react"

import {
  MobileBlogListWrapper,
  DesktopBlogListWrapper,
  DesktopBlogListHeading,
  DesktopBlogListContainer,
  ButtonContainer,
} from "./bloglist-styles"

import { BlogCard } from "./BlogCard"
import { Button } from "../../components/Button"
import i18n from "../../../config/i18n"
import { LocaleContext } from "../Layout"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"

export const BlogList = ({ blogs }) => {
  const lang = React.useContext(LocaleContext)
  const { locale } = lang
  const staticText = i18n[locale].stories

  return (
    <section id={staticText.id}>
      <MobileBlogList blogs={blogs} staticText={staticText} />
      <DesktopBlogList blogs={blogs} staticText={staticText} />
    </section>
  )
}

const MobileBlogList = ({ staticText, blogs }) => {
  const params = {
    spaceBetween: 50,
    slidesPerView: 1.3,
    loop: true,
  }

  return (
    <MobileBlogListWrapper>
      <DesktopBlogListHeading
        dangerouslySetInnerHTML={{ __html: staticText && staticText.heading }}
      />
      <Swiper {...params}>
        {blogs &&
          blogs.map((blog, index) => {
            return (
              <div key={index}>
                <BlogCard key={index} blog={blog} />
              </div>
            )
          })}
      </Swiper>
    </MobileBlogListWrapper>
  )
}

const DesktopBlogList = ({ staticText, blogs }) => {
  return (
    <DesktopBlogListWrapper>
      <DesktopBlogListHeading
        dangerouslySetInnerHTML={{ __html: staticText && staticText.heading }}
      />
      <DesktopBlogListContainer>
        {blogs &&
          blogs.map((blog, index) => {
            return <BlogCard key={index} blog={blog} />
          })}
      </DesktopBlogListContainer>
      <ButtonContainer>
        <Button to="/blog" noPadding text="READ ALL ARTICLES" />
      </ButtonContainer>
    </DesktopBlogListWrapper>
  )
}

export default BlogList
