import styled from "styled-components"

export const MobileBlogListWrapper = styled.div`
  margin-left: 20px;
  margin-top: 20px;
  width: calc(100% - 20px);
  box-sizing: border-box;

  @media (min-width: 800px) {
    display: none;
  }
`

export const DesktopBlogListWrapper = styled.div`
  display: none;

  @media (min-width: 800px) {
    display: block;
    padding: 100px 0;
  }
`
export const DesktopBlogListHeading = styled.div`
  font-family: "HelveticaNeue Condensed";
  font-size: 1.3rem;
  font-weight: bold;
  font-style: normal;
  line-height: 1.68;
  letter-spacing: 12.5px;
  text-align: left;

  @media (min-width: 800px) {
    margin: 20px 10%;
    font-size: 2rem;
  }
`

export const DesktopBlogListContainer = styled.div`
  max-width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  & > * + * {
    margin-left: 20px;
  }
`

export const ButtonContainer = styled.div`
  margin: 0 10%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
`
