import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { useTheme } from "../context/theme/ThemeContext"

import { SEO } from "../components/Seo"
import { Hero, Cards, Reviews, TextAndSlider, TextFullWidth } from "../slices"
import { Body } from "../styles/pages-styles/home-styles"
import { BlogList } from "../components/BlogList"
import { ContactForm } from "../components/ContactForm"
import { GoogleMap } from "../components/GoogleMap"
import { Waypoint } from "react-waypoint"

//TODO: make google map static
//TODO: add adress to footer

const Index = ({ data, pageContext: { locale }, location }) => {
  const { meta_title, meta_description, body } = data.home_page.data
  const blogs = data.blog_posts.edges
  const themeState = useTheme()
  const [googleMap, setGoogleMap] = useState(false)

  useEffect(() => {
    themeState.setTheme("white")
  }, [])

  const slices = body.map((slice, index) => {
    switch (slice.slice_type) {
      // These are the API IDs of the slices
      case "hero_section":
        return <Hero key={index} data={slice} />
      case "cards_section":
        return <Cards key={index} data={slice} />
      case "testimonial_section":
        return <Reviews key={index} data={slice} />
      case "text_and_slider_section":
        return <TextAndSlider key={index} data={slice} />
      case "text_section":
        return <TextFullWidth key={index} data={slice} />
      default:
        return null
    }
  })

  return (
    <>
      <SEO
        pathname={location.pathname}
        locale={locale}
        desc={meta_description}
        title={meta_title}
      />
      <Body>{body && slices}</Body>
      <BlogList blogs={blogs} />
      <ContactForm />

      <Waypoint onEnter={() => setGoogleMap(true)} topOffset="100px" />

      {googleMap && <GoogleMap />}
    </>
  )
}

export const pageQuery = graphql`
  query IndexQuery($locale: String!) {
    home_page: prismicHomepage(lang: { eq: $locale }) {
      data {
        meta_title
        meta_description
        body {
          ... on PrismicHomepageBodyHeroSection {
            slice_type
            items {
              text_line
            }
          }
          ... on PrismicHomepageBodyCardsSection {
            slice_type
            primary {
              left_card_label
              right_card_label
              left_button_text
              left_button_link_to
              right_button_text
              right_button_link_to
              left_card_body {
                html
              }
              left_card_heading {
                html
              }
              right_card_body {
                html
              }
              right_card_heading {
                html
              }
              background_image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomepageBodyTestimonialSection {
            slice_type
            items {
              reviewer_name
              review_body
              rating
              reviewer_picture {
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 800) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomepageBodyTextAndSliderSection {
            slice_type
            primary {
              title {
                html
              }
              text_body {
                html
              }
              button_text
              anchor_id
            }
            items {
              slide_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 499) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomepageBodyTextSection {
            slice_type
            primary {
              text_heading {
                html
              }
              text_body {
                html
              }
              background_color
              left_button_text
              right_button_text
              anchor_id
            }
          }
        }
      }
    }
    blog_posts: allPrismicBlogPost(
      filter: { lang: { eq: $locale } }
      limit: 3
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          uid
          first_publication_date
          last_publication_date
          data {
            date
            card_image {
              localFile {
                childImageSharp {
                  fluid(maxHeight: 450) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            title {
              html
              text
            }
            reading_time
            author {
              document {
                data {
                  first_name
                  last_name
                  about_person_short
                  profile_picture {
                    url
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export default Index
