import React, { useState } from "react"
import { Formik, Form } from "formik"
import * as yup from "yup"
import { useSpring } from "react-spring"
import i18n from "../../../config/i18n"

import YouTube from "react-youtube"
import { DialogOverlay, DialogContent } from "@reach/dialog"
import "@reach/dialog/styles.css"

import { LocaleContext } from "../Layout"
import { FormField } from "./FormField"
import { Button } from "../Button"

import {
  FormContainer,
  FormWrapper,
  ContactFormHeading,
  FormColumn,
  FormColumnLabel,
  PrivacyPolicy,
  LoaderContainer,
  ButtonContainer,
  Link,
} from "./contactform-styles"
import { RadioGroup } from "./RadioGroup"
import { LocalizedLink } from "../Primitives/LocalizedLink"
import { Loader } from "../Loader"

export const ContactForm = () => {
  const lang = React.useContext(LocaleContext)
  const { locale } = lang
  const staticText = i18n[locale].intakeForm

  const [intakeForm, setIntakeForm] = useState("personal-coaching")
  const [loading, setLoading] = useState(false)
  const [showDialog, setShowDialog] = useState(false)

  const validationSchema = yup.object({
    name: yup.string().required(staticText.errorName),
    email: yup
      .string()
      .required(staticText.errorEmailReq)
      .email(staticText.error),
    phone: yup
      .string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        staticText.errorPhone
      ),
  })

  const open = () => setShowDialog(true)
  const close = () => setShowDialog(false)

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true)
    return fetch(
      `https://api.formik.com/submit/innerstrength-amsterdam/intake-${intakeForm}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    )
      .then(() => {
        resetForm({})
        setTimeout(() => setLoading(false), 2000)
        setTimeout(() => open(), 2000)
      })
      .catch(err => {
        alert("Error, please try again later")
        console.log(err)
      })
  }

  const formAnimation = useSpring({
    opacity: loading ? 0 : 1,
  })

  const loaderAnimation = useSpring({
    opacity: loading ? 1 : 0,
  })

  const videoConfig = {
    height: "700px",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  }
  return (
    <>
      <FormContainer id="plan-intake">
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <ContactFormHeading
                dangerouslySetInnerHTML={{
                  __html: staticText && staticText.heading,
                }}
              />
              <FormWrapper style={formAnimation}>
                <FormColumn>
                  <FormColumnLabel>
                    {staticText.radioButtonText}
                  </FormColumnLabel>
                  <RadioGroup setIntakeForm={setIntakeForm} />
                </FormColumn>
                <FormColumn>
                  <FormColumnLabel>{staticText.formText}</FormColumnLabel>
                  <FormField
                    name="name"
                    type="text"
                    placeholder={staticText.placeholderName}
                  />
                  <FormField
                    name="email"
                    type="text"
                    placeholder={staticText.placeholderEmail}
                  />
                  <FormField
                    name="phone"
                    type="tel"
                    placeholder={staticText.placeholderPhone}
                  />
                  <PrivacyPolicy>
                    <input
                      name="accept-privacy-policy"
                      type="checkbox"
                      id="accept-privacy-policy"
                      required
                    />
                    <span>
                      {staticText.privacyPolicyText}
                      <Link to="/privacy-statement">privacy policy</Link>
                    </span>
                  </PrivacyPolicy>
                  <ButtonContainer>
                    <Button
                      noPadding
                      type="submit"
                      disabled={isSubmitting}
                      text={staticText.buttonText}
                    />
                  </ButtonContainer>
                </FormColumn>
              </FormWrapper>
            </Form>
          )}
        </Formik>
        <LoaderContainer style={loaderAnimation}>
          <Loader />
          <p>SUBMITTING...</p>
        </LoaderContainer>
      </FormContainer>

      <DialogOverlay
        style={{
          background: "hsla(0, 100%, 100%, 0.9)",
          display: "flex",
          alignItems: "center",
        }}
        isOpen={showDialog}
        onDismiss={close}
      >
        <DialogContent
          style={{
            padding: "0",
            width: "90vw",
            backgroundColor: "black",
            boxShadow: "0px 10px 50px hsla(0, 0%, 0%, 0.33)",
          }}
        >
          <YouTube
            opts={videoConfig}
            videoId={staticText.confirmationVideoId}
          />
        </DialogContent>
      </DialogOverlay>
    </>
  )
}

export default ContactForm
