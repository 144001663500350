import React, { useState } from "react"

import { RadioGroupWrapper } from "./radiogroup-styles"
import { RadioButton } from "./RadioButton"

export const RadioGroup = ({ setIntakeForm }) => {
  const [select, setSelect] = useState("personal-coaching")

  const handleSelectChange = value => {
    setSelect(value)
    setIntakeForm(value)
  }

  return (
    <RadioGroupWrapper>
      <RadioButton
        value="personal-coaching"
        id="personal-coaching"
        handleSelectChange={handleSelectChange}
        setSelect={setSelect}
        select={select}
      >
        <span>
          INNERSTRENGTH PERSONAL <br />
          COACHING
        </span>
      </RadioButton>
      <RadioButton
        value="innerstrength-way"
        id="innerstrength-way"
        handleSelectChange={handleSelectChange}
        setSelect={setSelect}
        select={select}
      >
        <span>
          INNERSTRENGTH <br /> WAY
        </span>
      </RadioButton>
    </RadioGroupWrapper>
  )
}

export default RadioGroup
