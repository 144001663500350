import React from "react"
import { useField } from "formik"

import {
  RadioButtonWrapper,
  RadioInput,
  RadioButtonLabel,
} from "./radiobutton-styles"

export const RadioButton = ({
  name,
  value,
  id,
  children,
  setSelect,
  handleSelectChange,
  select,
  ...props
}) => {
  const [field, meta, helpers] = useField(props)

  return (
    <RadioButtonWrapper onClick={() => handleSelectChange(value)}>
      <RadioInput
        {...props}
        {...field}
        required
        type="radio"
        name={name}
        value={value}
        checked={select === value}
        onChange={event => handleSelectChange(event)}
        id={id}
      />
      <RadioButtonLabel />
      {children}
    </RadioButtonWrapper>
  )
}

export default RadioButton
