import styled from "styled-components"

export const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100px;
  box-sizing: border-box;
  position: relative;
  border: 4px solid #343434;
  cursor: pointer;

  @media (min-width: 800px) {
    height: 100px;
  }

  & > span {
    margin-top: 0;
    margin-left: 10px;
    font-family: "HelveticaNeue Condensed";
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 7px;
    text-align: left;
    color: #343434;

    & > br {
      @media (min-width: 800px) {
        display: none;
      }
    }
  }
`
export const RadioButtonLabel = styled.label`
  position: absolute;
  left: 24px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 1px solid #343434;
  margin-top: 0;
`

export const RadioInput = styled.input`
  opacity: 0;
  margin-left: 24px;
  z-index: 1;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;

  &:hover ~ ${RadioButtonLabel} {
    background: #343434;
    &::after {
      cursor: pointer;
      content: "";
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 4px;
      background: #eeeeee;
    }
  }

  &:checked + ${RadioButtonLabel} {
    background: #343434;
    border: 1px solid #343434;

    &::after {
      cursor: pointer;
      content: "";
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 4px;
      box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
      background: white;
    }
  }
`
